* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f2f4f5;
  color: #1e1e1f;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
}

#root {
  margin: 0 24px;
  position: relative;
  min-height: 100vh;
}
